import { Component } from 'vue-property-decorator'
import { BaseMixin } from 'src/components/mixins/base'

import { SET_META } from 'booksprout-app'

@Component
export default class AffiliateDisclosure extends BaseMixin {
  created () {
    void this.$store.dispatch(SET_META, {
      title: 'Affiliate Disclosure',
      canonicalUrl: window.location.origin + this.websitePaths.affiliateDisclosure
    })
  }
}
